import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { WelcomeGuard } from "./guards/welcome.guard";
import { InsideGuard } from "./guards/inside.guard";
import { OutsideGuard } from "./guards/outside.guard";


const routes: Routes = [
    {
        path: '',
        redirectTo: 'blank',
        pathMatch: 'full'
    },
    {
        path: 'blank',
        loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankPageModule)
    },
    {
        path: 'startupfailure',
        loadChildren: () => import('./pages/outside/startupfailure/startupfailure.module').then(m => m.StartupFailurePageModule)
    },
    {
        path: 'welcome',
        loadChildren: () => import('./pages/outside/welcome/welcome.module').then(m => m.WelcomePageModule),
        canLoad: [OutsideGuard, WelcomeGuard]
    },
    {
        path: 'signinup',
        loadChildren: () => import('./pages/outside/signinup/signinup.module').then(m => m.SigninupPageModule),
        canLoad: [OutsideGuard]
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/outside/signup/signup.module').then(m => m.SignupPageModule),
        canLoad: [OutsideGuard]
    },
    {
        path: 'signin',
        loadChildren: () => import('./pages/outside/signin/signin.module').then(m => m.SigninPageModule),
        canLoad: [OutsideGuard],
        canActivate: [OutsideGuard]
    },
    {
        path: 'verification',
        loadChildren: () => import('./pages/outside/verification/verification.module').then(m => m.VerificationPageModule),
        canLoad: [OutsideGuard]
    },
    {
        path: 'dialoglist',
        loadChildren: () => import('./pages/inside/dialoglist/dialoglist.module').then(m => m.DialoglistPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'dialog/:id',
        loadChildren: () => import('./pages/inside/dialog/dialog.module').then(m => m.DialogPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'dialog/:id/profile',
        loadChildren: () => import('./pages/inside/profile/profile.module').then(m => m.ProfilePageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/inside/settings/settings.module').then(m => m.SettingsPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'help',
        loadChildren: () => import('./pages/inside/help/help.module').then(m => m.HelpPageModule),
    },
    {
        path: 'devices',
        loadChildren: () => import('./pages/inside/devices/devices.module').then(m => m.DevicesPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'pages',
        loadChildren: () => import('./pages/inside/pages/pages.module').then(m => m.PagesPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'contacts',
        loadChildren: () => import('./pages/inside/contacts/contacts.module').then(m => m.ContactsPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'profile-edit',
        loadChildren: () => import('./pages/inside/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'gallery-edit',
        loadChildren: () => import('./pages/inside/gallery-edit/gallery-edit.module').then(m => m.GalleryEditPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/inside/profile/profile.module').then(m => m.ProfilePageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'profile/:id',
        loadChildren: () => import('./pages/inside/profile/profile.module').then(m => m.ProfilePageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'visitors',
        loadChildren: () => import('./pages/inside/visitors/visitors.module').then(m => m.VisitorsPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'profile-search',
        loadChildren: () => import('./pages/inside/profile-search/profile-search.module').then(m => m.ProfileSearchPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./pages/inside/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
    },
    {
        path: 'failure',
        loadChildren: () => import('./pages/inside/failure/failure.module').then(m => m.FailurePageModule)
    },
    {
        path: 'contactcode',
        loadChildren: () => import('./pages/inside/contactcode/contactcode.module').then(m => m.ContactCodePageModule)
    },
    {
        path: 'whodoeswhat',
        loadChildren: () => import('./pages/inside/wmw/wmw.module').then(m => m.WmwPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'whodoeswhat/posting/:id',
        loadChildren: () => import('./pages/inside/wmw/wmw.module').then(m => m.WmwPageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'whodoeswhat/profile/:id',
        loadChildren: () => import('./pages/inside/profile/profile.module').then(m => m.ProfilePageModule),
        canLoad: [InsideGuard]
    },
    {
        path: 'qrcode',
        loadChildren: () => import('./pages/inside/qrcode/qrcode.module').then( m => m.QrcodePageModule)
    }


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {}
